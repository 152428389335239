import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as lasikStyles from "./lasik.module.scss"

const Lasik = () => {
  return (
    <div className={lasikStyles.wrapper}>
      <div className={lasikStyles.lasikGrid}>
        <h2 className={lasikStyles.header}>LASER SURGERY CO-MANAGEMENT</h2>
        <div className={lasikStyles.laserWrapper}>
          <StaticImage
            className={lasikStyles.laser}
            src="../../images/services/laser.png"
            placeholder="tracedSVG"
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            alt="Illustration of man shooting a laser beam from his eyes"
          />
        </div>

        <div className={lasikStyles.paragraphWrapper}>
          <p className={lasikStyles.paragraph}>
            In LASIK surgery, precise and controlled removal of corneal tissue
            by a special laser reshapes the cornea changing its focusing power.{" "}
            <br />
            Our Doctors are trained in post operative care following LASIK
            surgeries. We will remain in contact with the surgeon’s office and
            maintain the transfer of records between doctors.{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Lasik
