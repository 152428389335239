// extracted by mini-css-extract-plugin
export var adjustmentsCard = "labServices-module--adjustmentsCard--5LF8D";
export var cardGrid = "labServices-module--cardGrid--9lSdo";
export var cardGridCarousel = "labServices-module--cardGridCarousel--9yPmB";
export var cardHeader = "labServices-module--cardHeader--N0Jda";
export var cardImage = "labServices-module--cardImage--CVbq2";
export var cardParagraph = "labServices-module--cardParagraph--+NDw4";
export var cardsDesktop = "labServices-module--cardsDesktop--ZHQcc";
export var cardsMobile = "labServices-module--cardsMobile--hWpLC";
export var cardsWrapper = "labServices-module--cardsWrapper--HpCJA";
export var carousel = "labServices-module--carousel--giqd0";
export var inHouseCard = "labServices-module--inHouseCard--tlMsg";
export var sectionHeader = "labServices-module--sectionHeader--BOuXc";
export var wrapper = "labServices-module--wrapper--D+nZm";