import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as contactLensesStyles from "./contactLenses.module.scss"

const ContactLenses = () => {
  return (
    <div className={contactLensesStyles.wrapper}>
      <div className={contactLensesStyles.contactLensesGrid}>
        <h2 className={contactLensesStyles.header}>CONTACT LENSES</h2>
        <div className={contactLensesStyles.contactImageWrapper}>
          <StaticImage
            src="../../images/services/contactlens.png"
            placeholder="blurred"
            className={contactLensesStyles.contactImage}
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            alt="Woman trying on contact lenses"
          />
        </div>
        <div className={contactLensesStyles.paragraphWrapper}>
          <p className={contactLensesStyles.paragraph}>
            Contact lenses are a safe, effective, and popular means of
            correcting refractive vision problems. We offer all types of
            contacts for all ages including those "hard to fit" cases with
            astigmatism and those in need of bifocals. A contact lens evaluation
            or fitting is in addition to, and in conjunction with, the eyeglass
            exam, and therefore has a separate and additional fee (that may or
            may not be covered by your vision plan).
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContactLenses
