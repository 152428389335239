import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as examsStyles from "./exams.module.scss"

const Exams = () => {
  return (
    <div className={examsStyles.wrapper}>
      <div className={examsStyles.redLine}></div>
      <StaticImage
        className={examsStyles.eyecharts}
        src="../../images/services/eyecharts.png"
        placeholder="tracedSVG"
        tracedSVGOptions="dominantColor"
        alt="Illustration of an array of eyecharts"
      />
      <div className={examsStyles.examGrid}>
        <h2 className={examsStyles.header}>
          STATE OF THE ART EYE EXAMINATIONS
        </h2>
        <div className={examsStyles.ladyWrapper}>
          <StaticImage
            className={examsStyles.lady}
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            src="../../images/services/exam.png"
            placeholder="tracedSVG"
            alt="Vintage photo of a woman looking through a phoropter"
          />
        </div>

        <p className={examsStyles.paragraph}>
          Your eye health is important to us. Eye examinations at Modern Eye are
          thorough. Please allow 1 to 1½ hours for the examination itself, which
          includes dilation of the pupils (expect fuzzy vision and light
          sensitivity 1-2 hours afterward on the average - sunglasses are
          provided). Also offered: Optomap technology, the latest in ultra-wide
          field retinal imaging, which allows for extensive retinal viewing and
          recording, rendering pupil dilation unnecessary in many cases.
          <br />
          The health-check portion of the examination covers common eye diseases
          such as glaucoma, cataracts and macular degeneration. Signs of
          diabetes and high blood pressure and many other diseases and disorders
          can also be detected by an eye examination. To monitor your eye health
          - whether you wear vision correction or not - an eye examination is
          recommended every 1-2 years.{" "}
        </p>
      </div>
    </div>
  )
}

export default Exams
