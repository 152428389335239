import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as labServicesStyles from "./labServices.module.scss"
import { Carousel } from "react-responsive-carousel"
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"

const LabServices = () => {
  return (
    <div className={labServicesStyles.wrapper}>
      {/* mobile display */}
      <section className={labServicesStyles.cardsMobile}>
        <h2 className={labServicesStyles.sectionHeader}>
          EYEGLASS LAB SERVICES
        </h2>

        {/* mobile with dots */}
        <div className={labServicesStyles.cardsWrapper}>
          <Carousel
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={true}
            className={labServicesStyles.carousel}
            showStatus={false}
            showThumbs={false}
          >
            <div
              className={`${labServicesStyles.cardGrid} ${labServicesStyles.cardGridCarousel}`}
            >
              <h3 className={labServicesStyles.cardHeader}>
                IN-HOUSE FINISHING
              </h3>
              <p className={labServicesStyles.cardParagraph}>
                Our in-house certified opticians cut lenses for frames purchased
                at Modern Eye in addition to frames purchased elsewhere.
              </p>
              <StaticImage
                className={`${labServicesStyles.cardImage}`}
                src="../../images/services/lenses.png"
                placeholder="tracedSVG"
                alt=""
              />
            </div>

            <div
              className={`${labServicesStyles.cardGrid} ${labServicesStyles.cardGridCarousel}`}
            >
              <h3 className={labServicesStyles.cardHeader}>
                CUSTOM MEASUREMENTS
              </h3>
              <p className={labServicesStyles.cardParagraph}>
                Comprehensive measurements such as panoscopic tilt and vertex
                are taken for specialty lenses to reduce visual artifacts and
                optimize clarity.
              </p>
              <StaticImage
                className={`${labServicesStyles.cardImage}`}
                src="../../images/services/vertextilt.png"
                placeholder="tracedSVG"
                alt=""
              />
            </div>

            <div
              className={`${labServicesStyles.cardGrid} ${labServicesStyles.cardGridCarousel}`}
            >
              <h3 className={labServicesStyles.cardHeader}>
                FRAME ADJUSTMENTS
              </h3>
              <p className={labServicesStyles.cardParagraph}>
                Our opticians are skilled at fitting loose or crooked glasses
                and can even repair broken nosepads and temple tips on most
                frames.
              </p>
              <StaticImage
                className={`${labServicesStyles.cardImage}`}
                src="../../images/services/glassesparts.png"
                placeholder="tracedSVG"
                alt=""
              />
            </div>
          </Carousel>
        </div>
      </section>

      {/* desktop display */}
      <section className={labServicesStyles.cardsDesktop}>
        <h2 className={labServicesStyles.sectionHeader}>
          EYEGLASS LAB SERVICES
        </h2>
        <ul className={labServicesStyles.cardsWrapper}>
          <li
            className={`${labServicesStyles.cardGrid} ${labServicesStyles.inHouseCard}`}
          >
            <h3 className={labServicesStyles.cardHeader}>IN-HOUSE FINISHING</h3>
            <p className={labServicesStyles.cardParagraph}>
              Our in-house certified opticians cut lenses for frames purchased
              at Modern Eye in addition to frames purchased elsewhere.
            </p>
            <StaticImage
              className={`${labServicesStyles.cardImage}`}
              src="../../images/services/lenses.png"
              placeholder="tracedSVG"
              alt=""
            />
          </li>

          <li className={labServicesStyles.cardGrid}>
            <h3 className={labServicesStyles.cardHeader}>
              CUSTOM MEASUREMENTS
            </h3>
            <p className={labServicesStyles.cardParagraph}>
              Comprehensive measurements such as panoscopic tilt and vertex are
              taken for specialty lenses to reduce visual artifacts and optimize
              clarity.
            </p>
            <StaticImage
              className={`${labServicesStyles.cardImage} `}
              src="../../images/services/vertextilt.png"
              placeholder="tracedSVG"
              alt=""
            />
          </li>

          <li
            className={`${labServicesStyles.cardGrid} ${labServicesStyles.adjustmentsCard}`}
          >
            <h3 className={labServicesStyles.cardHeader}>FRAME ADJUSTMENTS</h3>
            <p className={labServicesStyles.cardParagraph}>
              Our opticians are skilled at fitting loose or crooked glasses and
              can even repair broken nosepads and temple tips on most frames.
            </p>
            <StaticImage
              className={`${labServicesStyles.cardImage}`}
              src="../../images/services/glassesparts.png"
              placeholder="tracedSVG"
              alt=""
            />
          </li>
        </ul>
      </section>
    </div>
  )
}

export default LabServices
