import React from "react"
import ContactLenses from "./contactLenses"
import Exams from "./exams"
import LabServices from "./labServices"
import Lasik from "./lasik"
import Optomap from "./optomap"
import * as servicesStyles from "./servicesTop.module.scss"

const ServicesTop = () => {
  return (
    <div className={servicesStyles.wrapper}>
      <h1 className={servicesStyles.visuallyHiddenHeader}>Services</h1>
      <Exams />
      <Optomap />
      <ContactLenses />
      <Lasik />
      <LabServices />
    </div>
  )
}

export default ServicesTop
