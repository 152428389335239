import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as optopmapStyles from "./optomap.module.scss"

const Optomap = () => {
  return (
    <div className={optopmapStyles.wrapper}>
      <div className={optopmapStyles.optomapGrid}>
        <h2 className={optopmapStyles.header}>OPTOMAP RETINAL IMAGING</h2>
        <div className={optopmapStyles.imageWrapper}>
          <StaticImage
            src="../../images/services/imaging.png"
            className={optopmapStyles.image}
            placeholder="tracedSVG"
            style={{
              display: `block`,
              marginLeft: `auto`,
              marginRight: `auto`,
            }}
            alt="Retinal imaging scan"
          />
        </div>
        <div className={optopmapStyles.paragraphWrapper}>
          <p className={optopmapStyles.paragraph}>
            With traditional, small-field, and even widefield retinal imaging,
            only 10-100⁰ of the retina can be captured in a single image.
            Optomap is the only true, clinically validated, ultra-widefield
            retinal imaging that can capture 82% or 200⁰ of the retina in a
            single capture – an increase of 50% over the next closest imaging
            device. With optomap auto-montage up to 97% or 220⁰ of the retina
            can be imaged with the multi capture montaging functionality.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Optomap
