import React from "react"
import ServicesTop from "../components/services/servicesTop"
import Seo from "../components/seo"

const Services = () => {
  return (
    <div>
      <Seo
        title="Services"
        description="We offer a wide range of Services including comprehensive Eye Examinations and Eyeglass lab services."
      />
      <ServicesTop />
    </div>
  )
}

export default Services
